<template>
  <div style='margin-top: 30px'>
    <el-row>
      <el-form ref='elForm' inline :model='formData' :rules='rules' label-width='120px'>
        <el-row>
          <el-col>
            <el-form-item label='转组红人'>
              <div style='display: flex;flex-direction: row;gap: 5px;width: 320px' >
                <artist-search @handleSelect='handleSelectArtist'></artist-search>
<!--                <el-link :icon='showMore?"el-icon-arrow-up":"el-icon-arrow-down"' @click='showMore=!showMore'>-->
<!--                  {{ showMore ? '隐藏' : '展开' }}-->
<!--                </el-link>-->
              </div>
<!--              <div v-if='showMore'>-->
<!--                <sign-artist-search-custom-->
<!--                  :type='``'-->
<!--                  @handleSelect='selectArtist'-->
<!--                  :show-first-group='false'-->
<!--                  style='width: 100%'-->
<!--                />-->
<!--              </div>-->

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label='转入部门-组' prop='group_id'>
              <CompanyCascader ref='refCompanySingleCascader' @handleSelect='selectDept'  style='width: 320px'/>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='submitForm' v-if="userPermissions.indexOf('sign_transfer_group') > -1">
                确认转组
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </el-row>
    <el-row>
      <el-col :span='20' :offset='2'>
        <ArtistGroupsList ref='refArtistGroupsList' />
      </el-col>
    </el-row>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ArtistGroupsList from '@/pages/sign_info/components/ArtistGroupsList'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import SignArtistSearchCustom from '@/components/artist/SignArtistSearchCustom'
import CompanyCascader from '@/components/dept/CompanyCascader'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'TransferGroup',
  components: { ArtistSearch, CompanyCascader, ArtistGroupsList, ArtistSearchCustom, SignArtistSearchCustom },
  props: [],
  data() {
    return {
      formData: {
        kolId: null,
        nickname: null,
        artist_id: null,
        group_id: null,
        sign_id: null,
        id: null
      },
      rules: {
        nickname: [{
          required: true,
          message: '请检索并选择转组红人',
          trigger: 'change'
        }],
        group_id: [{
          required: true,
          message: '请选择转入部门-组',
          trigger: 'change'
        }]
      },
      showMore: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    handleSelectArtist(obj) {
      if (obj && obj.id) {
        this.formData.artist_id = obj.id
        this.formData.nickname = obj.nickname
        this.showGroupsList()
      }
    },
    showGroupsList() {
      if (this.$refs['refArtistGroupsList'])
        this.$refs['refArtistGroupsList'].show(this.formData)
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        let msg = `此操作将把红人『${this.formData.nickname}』转到【${this.formData.group_name}】, 是否继续?`
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.commitSave()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      })
    },
    async commitSave() {
      if (!(this.formData.artist_id && this.formData.group_id)) {
        this.$notify.warning('红人和新组必选')
        return
      }
      let info = await this.$api.signInfoGroupTransfer(this.formData.artist_id, this.formData.group_id)
      if (info === 'ok') {
        this.$notify.success('转组成功')
        this.showGroupsList()
      }
    },
    async selectDept(val) {
      // console.log('val',val)
      let group_id = null
      if (val && val.length !== 0) {
        group_id = val[0]
      }
      this.formData.group_name = this.$refs['refCompanySingleCascader'].getNodeName(group_id)
      this.formData.group_id = group_id
    }
  }
}

</script>
<style scoped>
</style>
